<template>
  <div class="reset-password-container">
    <AppOnboarding>
      <template v-slot:left-panel>
        <OnboardingLeftPanel :title="$dictionary.signInLeftPanelTitle" :subtitle="$dictionary.signInLeftPanelSubtitle" :footerText="$dictionary.signInLeftPanelFooterText" />
      </template>
      <template v-slot:main-panel>
        <div class="reset-password-main">
          <div class="header-container">
            <router-link :to="{ name: 'login' }" tag="span">
              <i class="material-icons back-arrow" aria-hidden="true">arrow_back</i>
            </router-link>
            <OnboardingHeader :routerLink="{ name: 'select-goal', params: { type: 'user' } }" :tabIndex="4" />
          </div>
          <div class="reset-password-wrapper">
            <div class="reset-password-middle">
              <ValidationObserver #default="{ validate }">
                <form @submit.prevent="handleResetPassword(validate)">
                  <img class="reset-password-logo-mobile" alt="Company logo." />

                  <h1 class="h1-primary">Reset your password</h1>
                  <p>We'll send you an email with instructions on how to reset your password.</p>
                  <ValidationProvider tag="div" :rules="formValidations.email" name="email" class="email-validation-provider" #default="{errors}">
                    <div class="input-label">
                      <label for="reset-password-email">Email</label>
                      <router-link :to="{ name: 'login' }" tabindex="3">Go back to Sign In</router-link>
                    </div>
                    <input
                      id="reset-password-email"
                      type="email"
                      :class="{ 'email-input-error': errors.length }"
                      placeholder="email@example.com"
                      v-model.trim="email"
                      tabindex="1"
                    />
                    <FieldError v-bind="{ errors }" class="email-field-error" />
                  </ValidationProvider>
                  <Button
                    :buttonProps="{ id: 'reset-password-button', buttonText: 'Email me instructions', isLoading, loadingPercentage }"
                    type="submit"
                    class="reset-password-submit"
                    data-testid="reset-password-email-me"
                    tabindex="2"
                  />
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </template>
    </AppOnboarding>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { transitionRoutine } from '@/store/modules/transition/routines'

import { componentSizeRoutine } from '@/store/modules/geometricBackground/routines'
import { resetPassword } from '@/api/authorization'
import { toastTopCenterOptions } from '@/config/vue-toast'
import Button from '@/components/dynamicForms/Button.vue'
import FieldError from '@/components/forms/FieldError'
import AppOnboarding from '@/components/layout/AppOnboarding.vue'
import OnboardingLeftPanel from '@/components/OnboardingLeftPanel.vue'
import OnboardingHeader from '@/components/OnboardingHeader.vue'
import store from '@/store'

export default {
  name: 'resetPassword',
  components: { ValidationObserver, ValidationProvider, Button, FieldError, AppOnboarding, OnboardingLeftPanel, OnboardingHeader },
  data() {
    return {
      email: '',
      isLoading: false,
      loadingPercentage: 0,
      formValidations: {
        email: {
          required: true,
          email: true
        }
      }
    }
  },
  methods: {
    handleResetPassword(validate) {
      // Validating the form before proceed with the password change request.
      return validate()
        .then(validationResult => {
          if (validationResult) {
            this.isLoading = true

            this.loadingPercentage = 100

            return resetPassword(this.email).then(
              () => {
                this.isLoading = false

                this.$router.push({
                  name: 'forgot-password-check-email',
                  params: { email: this.email }
                })
              },
              error => {
                this.resetLoadingValues()

                this.$toasted.show(error.response, { ...toastTopCenterOptions, className: 'toasted-error' })
              }
            )
          }

          this.resetLoadingValues()
        })
        .catch(() => {
          this.resetLoadingValues()
        })
    },
    resetLoadingValues() {
      this.isLoading = false
      this.loadingPercentage = 0
    }
  },
  beforeMount() {
    // Setting correct slide transition and geometric background size for this route.
    store.dispatch(transitionRoutine.TRIGGER, 'slide-right')
    store.dispatch(componentSizeRoutine.TRIGGER, 'one-quarter-width')
  },
  metaInfo() {
    return {
      link: [{ rel: 'canonical', href: `${process.env.VUE_APP_PROD_URL}forgot-password` }]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.reset-password-container {
  /deep/ .app-onboarding-container .left-panel {
    width: 25vw;
    background-position: center;
    background-size: contain;
  }

  /deep/ .app-onboarding-container .main-panel {
    width: 75vw;
    display: flex;
  }

  .reset-password-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .header-container {
      display: flex;
      align-items: center;
      align-self: flex-end;

      .back-arrow {
        display: none;
      }
    }

    .reset-password-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .reset-password-logo-mobile {
        content: url('../../assets/images/config/logo-mobile.svg');
      }

      img {
        display: none;
      }

      .reset-password-middle {
        width: 390px;
        max-width: calc(100vw - 48px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        span form {
          h1 {
            font-size: 22px;
            font-weight: 500;
            letter-spacing: -0.4px;
            line-height: 26px;
            margin-bottom: 16px;
          }

          p {
            font-size: 18px;
            letter-spacing: -0.4px;
            line-height: 24px;
            margin-bottom: 32px;
          }

          .email-validation-provider {
            margin-bottom: 40px;

            .input-label {
              width: 390px;
              max-width: calc(100vw - 48px);
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              label {
                font-size: 16px;
                letter-spacing: -0.4px;
                line-height: 22px;
                margin-bottom: 6px;
              }

              a {
                text-align: right;
                font-size: 14px;
                letter-spacing: -0.4px;
                line-height: 16px;
                color: var(--color-primary-400);
                margin-bottom: 7px;
              }
            }

            input {
              height: 56px;
              width: 390px;
              max-width: calc(100vw - 48px);
              font-size: 14px;
              letter-spacing: -0.4px;
              line-height: 16px;
              box-sizing: border-box;
              border: 1px solid var(--color-secondary-50);
              border-radius: 8px;
              background-color: $color-white;
              color: var(--color-primary-500);
              margin-bottom: 10px;
            }

            .email-input-error {
              border: 1px solid var(--color-error-500);
            }

            .email-field-error {
              color: var(--color-error-500);
            }
          }

          /deep/ .reset-password-submit {
            width: 245px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-xlg) {
    /deep/ .app-onboarding-container .left-panel {
      width: 35vw;
      background-position: center;
      background-size: contain;
      display: flex;
    }

    /deep/ .app-onboarding-container .main-panel {
      width: 65vw;
    }
  }

  @media only screen and (max-width: $breakpoint-md) {
    /deep/ .app-onboarding-container .left-panel {
      width: 0;
      display: none;
    }

    /deep/ .app-onboarding-container .main-panel {
      width: 100vw;
    }

    .reset-password-main .header-container .back-arrow {
      display: block;
      position: absolute;
      top: 29px;
      left: 24px;
      color: var(--color-primary-400);
    }
  }

  @media only screen and (max-width: $breakpoint-md) {
    .reset-password-main .reset-password-wrapper {
      padding: 24px;

      img {
        display: block;
        width: 170px;
        max-width: 170px;
        margin-bottom: 34px;
      }

      h1 {
        margin-bottom: 24px;
      }

      .reset-password-middle span form .email-validation-provider .input-label a {
        font-size: 12px;
      }
    }
  }
}
</style>
